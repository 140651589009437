import React from "react";

export default function Home() {
  return (
    <div className="cube__face cube__face--home">
      <div className="home">
        <div className="home__grid">
          <h1 className="home__heading">Hello!</h1>
          <p className="home__text">
            My name is Nate Mailhiot. I'm a freelance website developer from the
            Boston area.
          </p>
        </div>
      </div>
    </div>
  );
}
