import React from "react";

export default function About() {
  return (
    <div className="cube__face cube__face--about">
      <div className="about">
        <h1 className="about__heading">Nate Mailhiot</h1>
        <p className="about__text">
          I am a full stack software engineer from the Boston area. My previous
          background is working with special needs people. I did this for about
          8 years. My coding journey started back in October of 2018. Coding
          went from a hobby that I enjoy, to making a career change fairly
          quickly. It led me to taking General Assembly's Software Engineering
          Immersive bootcamp in 2019. Since then I have been working on
          furthering my developer skill set. My area of expertise is working on
          all things front end. Especially in React. Outside of coding I enjoy
          fishing, playing guitar, hiking, being with friends and so much more!
          If you are looking for a landing page or hard working developer feel
          free to contact me!
        </p>
        <div className="about__img"></div>
      </div>
    </div>
  );
}
